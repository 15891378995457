import React, {useState} from 'react';
import './DeleteAccount.css';
import {auth, db} from "../firebase";
import {ref, set} from "firebase/database";
import {signInWithEmailAndPassword} from "firebase/auth";
import {toast, ToastContainer} from "react-toastify";

const DeleteAccount = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    async function addUserToDeletionNode(email, uid) {
        toast.success("Your account deletion request has been received.", {
            position: "top-center",
        });

        await set(ref(db, 'account_deletion_requests/' + uid), {
            email: email,
            timestamp: new Date().getTime()
        });

        setEmail('')
        setPassword('')
    }

    const handleDeleteAccount = (event) => {
        event.preventDefault()
        setIsLoading(true);
        signInWithEmailAndPassword(auth, email.trim(), password.trim()).then(response => {
            const email = response.user.email;
            const uid = response.user.uid;

            addUserToDeletionNode(email, uid);

        }).catch(() => toast.error('Error validating the user. Please check your credentials.')).finally(() => setIsLoading(false))
    };

    return (
        <div className="delete-account-form">
            <h2>Delete Account</h2>
            <label>Email:</label>
            <input style={{marginBottom: 30}} type="email" value={email} onChange={handleEmailChange}/>

            <label>Password:</label>
            <input type="password" value={password} onChange={handlePasswordChange}/>

            <div style={{marginTop: 20}}>
                <button
                    onClick={handleDeleteAccount}
                    disabled={email === '' || password === '' || isLoading}
                >
                    Delete Account
                </button>
            </div>

            <ToastContainer position="top-center"/>
        </div>
    );
};

export default DeleteAccount;
