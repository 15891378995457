import {initializeApp} from '@firebase/app';
import {getDatabase} from "firebase/database";
import {getAuth, onAuthStateChanged} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCyEh5CH8yQN50reGaQfAGVgbtu7YfNSD8",
    authDomain: "cpaidevotionalserverdatabase.firebaseapp.com",
    databaseURL: "https://cpaidevotionalserverdatabase.firebaseio.com",
    projectId: "cpaidevotionalserverdatabase",
    storageBucket: "cpaidevotionalserverdatabase.appspot.com",
    messagingSenderId: "208275060327",
    appId: "1:208275060327:web:08274d28161354407d46c2",
    measurementId: "G-WWTCP5FH1H"
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const auth = getAuth(app)

export {db, auth, onAuthStateChanged};
