import './App.css';
import Home from "./pages/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteAccount from "./pages/DeleteAccount";

import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <BrowserRouter basename={"/"}>

            <React.Suspense fallback={<div
                className="animated fadeIn pt-3 text-center">Loading...</div>}>
                <Routes>
                    <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
                    <Route path={"/delete-account"} element={<DeleteAccount/>}/>
                    <Route path={"/"} element={<Home/>}/>
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    );
}

export default App;
