import React, {useEffect, useState} from 'react';
import {CardMedia, CircularProgress} from "@mui/material";
import dayjs from "dayjs";
import './styles.css';
import Button from "@mui/material/Button";
import {DatePicker} from "@mui/x-date-pickers";

const DATE_FORMAT = 'DD.MM.YYYY';

const Devotional = () => {

    const [devotional, setDevotional] = useState();
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(dayjs().format(DATE_FORMAT));

    useEffect(() => {
        fetch(`https://cpai.guidetryb.com/api/devotionals/${date}`)
            .then(res => res.json())
            .then(devotional => {
                setDevotional(devotional);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [date]);

    const onSetDate = (date) => {
        setDate(date.format(DATE_FORMAT));
    }

    return (
        loading ? <CircularProgress/> :
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {devotional && <CardMedia
                    component="img"
                    height="200"
                    image={devotional?.image}
                    alt={devotional?.title}
                />}

                <div className={"center-align mt-4"} style={{display: 'flex', alignItems: 'center'}}>
                    <DatePicker
                        label="Select Date"
                        value={dayjs(date, DATE_FORMAT)}
                        onChange={onSetDate}
                        maxDate={dayjs(date, DATE_FORMAT)}
                        inputVariant="outlined"
                        style={{marginTop: 16, marginLeft: 8}}
                    />
                </div>
                {devotional && <h1 className={"center-align"}>{devotional.title}</h1>}
                {devotional && <div className={"center-align"}>Bible in a
                    Year: {(devotional.bibleInAYear ? JSON.parse(devotional.bibleInAYear) : []).map(passage => <Button
                        key={passage}
                        target={'_blank'}
                        href={`https://www.biblegateway.com/passage/?search=${passage}&version=KJV`}>{passage}</Button>)}</div>}
                {devotional && <div className={"center-align"}>

                    <p className={'memory-verse'}>{devotional.memoryVerse}</p>
                    <a href={`https://www.biblegateway.com/passage/?search=${devotional.memoryVersePassage}&version=KJV`}
                       className={'memory-verse-passage'}>{devotional.memoryVersePassage}</a>

                </div>}

                {devotional && <div className={'center-align mt-5'}>
                    <span className={'full-passage'}>Read: <Button variant="contained" target={'_blank'}
                                                                   href={`https://www.biblegateway.com/passage/?search=${devotional.fullPassage}&version=KJV`}> {devotional.fullPassage}</Button></span>
                </div>}

                {devotional && devotional.fullText.split("\n").map((section, index) => <span key={index}
                                                                                             className={'center-align mt-3 full-text'}>
                    {section}
                </span>)}


                {devotional && <div className={"center-align mt-5 "} >Prayer Burden: {devotional.prayerBurden}</div>}
                {devotional && <div className={"center-align mt-5 "} >Thought of the day: {devotional.thoughtOfTheDay}</div>}

               <div className={"mt-5 center-align flex-column"} >
                <Button  href="/privacy-policy">Privacy Policy</Button>
                <Button href="/delete-account">DELETE ACCOUNT</Button>
               </div>
            </div>
    );
};

export default Devotional;